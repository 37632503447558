import { Injectable } from '@angular/core'; 

@Injectable()
export class LinkService {

  private _linkList = [
    { text: 'Contáctanos',link:'tel:+56954458478',icon:'call' },
    { text: 'Consultas por WhatsApp ',link:'https://wa.me/56954458478',icon:'chat' },

  ];

  getLinkList() {
    return this._linkList;
  }
}