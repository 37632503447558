import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { PagesComponent } from './pages/pages.component';
import { BlankComponent } from './pages/blank/blank.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { ErrorComponent } from './pages/errors/error/error.component';
import { AuthGuard } from './guards/auth.guard';

export const routes: Routes = [
    {
        path: '',
        component: PagesComponent, children: [
            { path: '', loadChildren: () => import('./pages/dashboard/dashboard.module')
            .then(m => m.DashboardModule), data: { breadcrumb: 'Dashboard' } , canActivate: [AuthGuard]},

            {
                path: 'cotizador-productos',
                loadChildren: () => import('./pages/price/price.module')
                    .then(m => m.PriceModule), data: { breadcrumb: 'Cotizador de productos' }, canActivate: [AuthGuard]},
    
            { path: 'profile', loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule), data: { breadcrumb: 'Profile' } , canActivate: [AuthGuard]},
            { path: 'blank', component: BlankComponent, data: { breadcrumb: 'Blank page' } },
        ]
    },

    { path: 'account', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },
    { path: 'error', component: ErrorComponent, data: { breadcrumb: 'Error' } },
    { path: '**', component: NotFoundComponent }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
                 useHash: true
        })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
