import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Usuario } from '../models/usuario.model';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import jwt_decode from "jwt-decode";


@Injectable({
  providedIn: 'root'
})
export class AuthService {


  public urlRegistro = "https://ws.cotizador.impac-online.cl/account/register";
  public urlForgot = "https://ws.cotizador.impac-online.cl/api/auth/forgot";
  public urlLogin = "https://ws.cotizador.impac-online.cl/api/login";
  public urlprofile = "https://ws.cotizador.impac-online.cl/api/profile/ver/";

  textToken: any;

  constructor(private http: HttpClient, public router: Router) {
    this.readToken();
  }

  

  public authForgot(username) {
    return this.http.post(this.urlForgot, username,{ responseType: 'text' })
    .pipe(
      map(resp => {
         return resp;
      })
    );

  }








  login(usuario: Usuario) {

    const authData = {
      ...usuario

    };

    return this.http.post(this.urlLogin, authData
    ).pipe(

      map(resp => {

        this.saveToken(resp['token']);
        return resp;
      })
    );

  }

  nuevoUsuario(usuario: Usuario) {

    const authData = {
      ...usuario,
      returnSecureToken: true
    };

    return this.http.post(this.urlRegistro, authData, { responseType: 'text' }).pipe(
      map(resp => {
         return resp;
      })
    );

  }
  saveToken(textToken: string) {


    

    let token: any = jwt_decode(textToken);

    



    this.textToken = textToken;
    localStorage.setItem('token', textToken);
    let hoy = new Date();
    let now = new Date((token.exp * 1000));


    localStorage.setItem('exp', now.getTime().toString());

  }
  isAuthenticated(): boolean {


    const exp = Number(localStorage.getItem('exp'));
    const expDate = new Date();
    expDate.setTime(exp);

    if (expDate > new Date()) {

      return true;
    }
    else {

      localStorage.removeItem('token');
      localStorage.removeItem('foreigner');
      localStorage.removeItem('exp');
      return false;
    }


  }
  logout() {

    localStorage.removeItem('token');
    localStorage.removeItem('foreigner');
    localStorage.removeItem('exp');
    this.router.navigateByUrl('/account');

  }
  profile() {


    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("token")
    });
    let options = { headers: headers };
    return this.http.get<Usuario[]>(this.urlprofile, options);

  }




  readToken() {

    if (localStorage.getItem('token')) {
      this.textToken = localStorage.getItem('token');

    } else {
      this.textToken = '';
    }

    return this.textToken;

  }

}
