import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { ItemPriceModel } from "../../models/item-price.model";
import { PackPriceModel } from 'src/app/models/pack-price.model';
import { Customer } from 'src/app/models/customer.model';


@Injectable({
  providedIn: 'root'
})
export class ListPriceService {

  public urlProduct = "https://ws.cotizador.impac-online.cl/list/products/";
  public urlfindProduct = "https://ws.cotizador.impac-online.cl/find-products/";
  public urlfindProductPack = "https://ws.cotizador.impac-online.cl/list/find-product-pack/";
  public urlProductAudit = "https://ws.cotizador.impac-online.cl/api/audit/product/";
  public urlOfvuCustomer = "https://ws.cotizador.impac-online.cl/api/ofvu/customer/";
  public urlOfvuCreate = "https://ws.cotizador.impac-online.cl/api/ofvu/create/";
  public urlProductMostViwed = "https://ws.cotizador.impac-online.cl/list/products/most-viewed/";
  constructor(private http: HttpClient) {
  }
  public getOfvuCustomer(): Observable<Customer> {
    return this.http.get<Customer>(this.urlOfvuCustomer);
  }

  public getProductPack(sku: string) {
    return this.http.get<PackPriceModel[]>(this.urlfindProductPack + sku);
  }

  public getProductMostViwed() {
    return this.http.get<ItemPriceModel[]>(this.urlProductMostViwed);
  }

  public getProductDiscountTerm(term: string): Observable<ItemPriceModel[]> {
    return this.http.get<ItemPriceModel[]>(this.urlProduct + term);

  }

  postAuditProduct(item) {
    return this.http.post(this.urlProductAudit, item);
  }

  createOfvu(of) {
    return this.http.post(this.urlOfvuCreate, of);
  }

}
