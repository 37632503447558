import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Customer } from 'src/app/models/customer.model';
import { ListPriceService } from 'src/app/pages/price/list-price.service';
import { AuthService } from 'src/app/services/auth.service';
import { LinkService } from './link.service';



export interface DetailCustomer {
  name: string;
  value: any;

}

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers:[LinkService]
})


export class UserMenuComponent implements OnInit {

  perfil: Customer;

  dataCustomer: MatTableDataSource<DetailCustomer>;
  dataCustomerColumns: string[] = ['name', 'value'];
  public linkList: Array<any>;
  constructor(private auth: AuthService, private lpm: ListPriceService,private _linkService: LinkService) {
    this.linkList = this._linkService.getLinkList();
   }




  ngOnInit() {
    this.dataCustomer = new MatTableDataSource([]);
    this.getOfvuCustomer();


  }



  logOut() {

    this.auth.logout();

  }

  getOfvuCustomer() {


    this.lpm.getOfvuCustomer()
      .subscribe({
        next: (v) => {
          this.perfil = v as Customer;
          this.dataCustomer.data = [];

          this.dataCustomer.data = [
            { name: "VENDEDOR", value: v.vendedor.toUpperCase() },
            { name: "E-MAIL", value: v.evendedor }

          ]

        },
        error: (e) => {

        },
      });

  }


  openLink(link: string) {
    window.open(link);
  }

}
