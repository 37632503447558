import { Injectable } from '@angular/core';
import {
  HttpInterceptor
} from '@angular/common/http';
import { AuthService } from '../services/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor{
  constructor(private auth: AuthService) {
  }
  intercept(request, next) {
    request = request.clone({
      setHeaders: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth.readToken()}`
      }
    });
    return next.handle(request);
  }



  
}

